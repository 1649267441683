import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Customer testimonials',
    paragraph: "At our company, we are confident in the quality of our custom mobile app development services. But don't just take our word for it – listen to what our satisfied customers have to say.",
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — As the VP of a healthcare organization, I was skeptical about the value of a custom mobile app. But after working with the team at App Robot, I am now a believer. Their expertise and professionalism made the process easy and stress-free, and the end result was a high-quality app that has greatly improved communication and collaboration within our organization. Our employees and patients are thrilled with the app, and I am confident it will help us continue to provide the best possible care. I highly recommend App Robot for anyone looking to develop a custom mobile app.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Suresh K.</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">VP (Healthcare)</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — As the VP of a financial services company, I was hesitant to invest in a custom mobile app. But after partnering with App Robot, I am glad we took the leap. Their team expertly translated our vision into a user-friendly app that has greatly improved the way we do business. Our customers are thrilled with the added convenience and flexibility, and we have seen a significant increase in engagement and revenue since launching the app. I highly recommend App Robot for anyone looking to develop a custom mobile app.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Christopher A.</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">VP (Banking)</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — As the CTO of an AI company, I am thrilled with the successful integration of our technology into the custom mobile app developed by App Robot. Their team was able to understand and accommodate our complex AI algorithms, resulting in a seamless and intuitive user experience for our customers. The app has been a huge success and has helped us establish ourselves as a leader in the industry. I am extremely impressed with the level of expertise and professionalism demonstrated by App Robot and highly recommend them for anyone looking to incorporate AI into their custom mobile app.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Andrei S.</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">CTO (Technology)</a>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;